import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';
import Link from '../Link';
import RenderStaticHTML from '../RenderStaticHTML';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-test-results`;

const TestResults = props => {
	const {
		heading,
		description,
		imageLeft,
		imageRight,
		resultsEyebrow,
		resultsHeading,
		resultsCta,
		resultsChartTitles,
		resultsChart,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<Reveal>
					<div className={`${ ns }__container`}>
						{heading && (
							<h2 className={`${ ns }__heading`}>
								{heading}
							</h2>
						)}

						{description && (
							<div className={`${ ns }__description`}>
								{description}
							</div>
						)}
						{(imageLeft && imageRight) && (
							<div className={`${ ns }__images`}>
								<div className={`${ ns }__image left`}>
									<Image {...imageLeft} />
									{imageLeft.helperText && (
										<h6 className={`${ ns }__image-helper`}>
											{imageLeft.helperText}
										</h6>
									)}
								</div>

								<div className={`${ ns }__image right`}>
									<Image {...imageRight} />
									{imageRight.helperText && (
										<h6 className={`${ ns }__image-helper`}>
											{imageRight.helperText}
										</h6>
									)}
								</div>
							</div>
						)}

						<div className={`${ ns }__results`}>
							<div className={`${ ns }__results-copy`}>
								{resultsEyebrow && (
									<div className={`${ ns }__results-eyebrow`}>
										{resultsEyebrow}
									</div>
								)}

								{resultsHeading && (
									<h4 className={`${ ns }__results-heading`}>
										{resultsHeading}
									</h4>
								)}

								{(resultsCta && resultsCta.link && resultsCta.label) && (
									<div className={`${ ns }__results-cta`}>
										<Link to={resultsCta.link}>
											{resultsCta.label}
										</Link>
									</div>
								)}
							</div>

							<div className={`${ ns }__results-chart`}>
								<div className={`${ ns }__results-chart-titles`}>
									{resultsChartTitles.map(title => {
										return (
											<div className={`${ ns }__results-column-title`} key={uniqueId('violux')}>
												{title}
											</div>
										);
									})}
								</div>
								<div className={`${ ns }__results-chart-rows`}>
									{resultsChart.map(row => {
										return (
											<div className={`${ ns }__results-chart-row`} key={uniqueId('violux')}>
												{row.map(column => {
													return (
														<div className={`${ ns }__results-chart-column`} key={uniqueId('violux')}>
															<RenderStaticHTML html={column} />
														</div>
													);
												})}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</Reveal>
			</div>
		</div>
	);
};

export default TestResults;
