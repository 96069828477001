import React, { useContext } from 'react';
import classNames from 'classnames';

import StoreContext from '../context/StoreContext';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import ImageTextStacked from '../components/ImageTextStacked';
import AlternatingImageText from '../components/AlternatingImageText';
import UvcShowcase from '../components/UvcShowcase';
import TestResults from '../components/TestResults';
import HowItWorks from '../components/HowItWorks';
import Product5050 from '../components/Product5050';

const nsBase = 'page';
const ns = `${ nsBase }-technology`;

const Technology = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		products,
	} = useContext(StoreContext);

	return (
		<div className={rootClassnames}>
			<SEO title={'Technology'} />
			<Hero
				heading={'Your world in a cleaner light.'}
				description={'Breakthrough smart UV-C light cleaning technology you can trust.'}
				image={{
					mainImageMobile: '/assets/images/technology/Technology_Hero.jpg',
					altText: 'Kitchen counter',
				}}
			/>
			<ImageTextStacked
				modifier={'what-is-uvc'}
				heading={'What is UV-C?'}
				description={'Ultraviolet (UV) light, which mostly comes from the sun, is invisible to the human eye. UV is divided into three bands: UV-A, UV-B and UV-C. UV-C, the light we use in Luma, is the highest energy portion of the UV spectrum and the only type that is germicidal and capable of killing germs, bacteria and viruses.'}
				image={{
					altText: 'A diagram illustrating what UVC light looks like on a spectrum',
					mainImageMobile: '/assets/images/technology/UVC_Diagram.png',
				}}
			/>
			<AlternatingImageText
				modifier={'dna'}
				hTag={'h2'}
				heading={'Molecular Disinfection.'}
				paragraph={'Microorganisms replicate to infect hosts and spread disease. UV-C light interrupts this process by photochemically altering and breaking apart the molecular bonds within their DNA or RNA, destroying their ability to function or reproduce, effectively killing the organism.'}
				altText={'DNA image'}
				mainImageMobile={'/assets/images/dna-new.jpg'}
				imgOnLeft
				dataMedia
				last
			/>
			<UvcShowcase
				heading={'Luma’s proprietary UV-C light cleaning technology <span>maximizes disinfection  performance via primary and light-path estimated reflected photonic energy.</span>'}
				rows={[
					{
						image: {
							mainImageMobile: '/assets/images/technology/Technology_LightPowered.jpg',
							altText: 'UVC Showcase 1',
						},
						heading: 'Light powered.',
						description: 'Luma’s ultraviolet reactor utilizes an array of high-quality UV-C Lamps that are optimized to provide enhanced light focus and strength.',
						infoSnippets: [
							{
								heading: 'up to 4',
								paragraph: 'Germicidal  UV-C Lamps',
							},
							{
								heading: 'up to 32w',
								paragraph: 'Reactor',
							},
						],
					},
					{
						image: {
							mainImageMobile: '/assets/images/technology/Technology_360Clean.jpg',
							altText: 'UVC Showcase 2',
						},
						heading: '360° of clean.',
						description: 'Luma’s uniquely designed structure and premium materials are engineered to provide superior coverage and saturation for cleaning large objects or multiple objects simultaneously.',
						infoList: [
							'Curved SU304 stainless steel interior.',
							'Available floor options include stainless steel grate and optical quartz',
							'2,210 cubic inch capacity',
						],
					},
				]}
			/>
			<TestResults
				// heading={'Microbial sterilization in minutes.'}
				// eslint-disable-next-line
				// description={'Luma destroys 99.99%* of germs, bacteria and viruses on the surface of objects.'}
				// imageLeft={{
				// 	mainImageMobile: '/assets/images/test-results-1.png',
				// 	altText: 'Test Result 1',
				// 	helperText: '01. Before',
				// }}
				// imageRight={{
				// 	mainImageMobile: '/assets/images/test-results-2.png',
				// 	altText: 'Test Result 2',
				// 	helperText: '02. After',
				// }}
				// resultsEyebrow={'Test Results'}
				resultsHeading={'IAC laboratory certified to kill 99.9%* of germs, bacteria and viruses.'}
				// resultsCta={{
				// 	link: '/',
				// 	label: 'View Test Results',
				// }}
				resultsChartTitles={
					[
						'Microorganism',
						'Test Results',
					]
				}
				resultsChart={[
					[
						'Coronavirus \n(OC43)',
						'Kills 99.99% <br /><a href="/assets/pdfs/Violux_Report_OC43.pdf">View Results</a>',
					],
					[
						'Methicillin-Resistant Staphylococcus Aureus \n(MRSA)',
						'Kills 99.99% <br /><a href="/assets/pdfs/Violux_Report_MRSA.pdf">View Results</a>',
					],
					[
						'Staphylococcus Aureus \n(Staph)',
						'Kills 99.9% <br /><a href="/assets/pdfs/Violux_Report_MSSA.pdf">View Results</a>',
					],
					[
						'Escherichia Coli \n(E. Coli)',
						'Kills 99.9% <br /><a href="/assets/pdfs/Violux_Report_ecoli.pdf">View Results</a>',
					],
					[
						'Pseudomonas Aeruginosa \n(Bacterial Infections)',
						'Kills 99.9% <br /><a href="/assets/pdfs/Violux_Report_Pseudomonas.pdf">View Results</a>',
					],
					[
						'Klebsiella Pneumoniae \n(Bacterial Infections)',
						'Kills 99.99% <br /><a href="/assets/pdfs/Violux_Report_KPC.pdf">View Results</a>',
					],
					[
						'Salmonella Typhimurium \n(Salmonella)',
						'Kills 99.9% <br /><a href="/assets/pdfs/Violux_Report_Salmonella.pdf">View Results</a>',
					],
					[
						'Influenza A H1N1 \n(Common Flu)',
						'Kills 99.9% <br /><a href="/assets/pdfs/Violux_Report_H1N1_10272020.pdf">View Results</a>',
					],
				]}
			/>
			<HowItWorks
				heading={'How It Works.'}
				hTag={'h2'}
				blocks={[
					{
						image: '/assets/images/technology/how_1.png',
						heading: '1.',
						description: 'Household items can carry up to 10 times more harmful bacteria than a toilet seat–remotes, cell phones, tablets, keys, pet toys, baby toys… you name it. To disinfect your belongings, simply place your items inside the Violux Luma and securely close the door.',
					},
					{
						image: '/assets/images/technology/how_2.png',
						heading: '2.',
						description: 'During a single cycle, the Luma reactor emits a 360 degree Ultraviolet-C 254 nanometer wavelength light that disinfects up to 99.9%* of viruses, bacteria and germs on all the items placed inside.',
					},
					{
						image: '/assets/images/technology/how_3.png',
						heading: '3.',
						description: 'Use the Violux mobile app to track your Luma usage, schedule reminders, and receive notifications helping you to intimately know and trust the cleanliness of the objects around you.',
					},
				]}
			/>
			<Product5050 products={products} />
		</div>
	);
};

export default Technology;
