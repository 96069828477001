import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import RenderStaticHTML from '../RenderStaticHTML';
import Image from '../Image';
import { dataInView } from '../Reveal/Animations';
// import Reveal from '../Reveal';

gsap.registerPlugin(ScrollTrigger);

const nsBase = 'component';
const ns = `${ nsBase }-uvc-showcase`;

const UvcShowcase = props => {
	const {
		heading,
		rows,
	} = props;

	const targetElement = useRef();

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	useEffect(() => {
		// const body = document.querySelector('body');
		const target = targetElement.current;
		dataInView(target, 'uvc');

		return () => {
			ScrollTrigger.getById('uvc').kill();
		};
	}, []);

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container`} ref={targetElement}>
				<div className={'container-fluid'}>
					<div className={`${ ns }__heading h3`}>
						<RenderStaticHTML html={heading} />
					</div>
				</div>
				<div className={`${ ns }__rows`}>
					{rows.map(row => {
						return (
							<div className={`${ ns }__row`} key={uniqueId('violux')}>
								<div className={`${ ns }__image`}>
									<Image {...row.image} />
								</div>
								<div className={`${ ns }__content-container`}>
									<div className={`${ ns }__content`}>
										<h2 className={`${ ns }__content-heading`}>
											{row.heading}
										</h2>

										<div className={`${ ns }__content-description`}>
											{row.description}
										</div>

										{row.infoSnippets && (
											<div className={`${ ns }__content-snippets`}>
												{row.infoSnippets.map(snippet => {
													return (
														<div className={`${ ns }__content-snippet`} key={uniqueId('violux')}>
															<h4 className={`${ ns }__content-snippet-heading`}>
																{snippet.heading}
															</h4>
															<div className={`${ ns }__content-snippet-paragraph`}>
																{snippet.paragraph}
															</div>
														</div>
													);
												})}
											</div>
										)}

										{row.infoList && (
											<div className={`${ ns }__content-list`}>
												{row.infoList.map(listItem => {
													return (
														<div className={`${ ns }__content-list-item`} key={uniqueId('violux')}>
															{listItem}
														</div>
													);
												})}
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default UvcShowcase;
