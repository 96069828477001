import React from 'react';
import classNames from 'classnames';

import Image from '../Image';
import RenderStaticHTML from '../RenderStaticHTML';

const nsBase = 'component';
const ns = `${ nsBase }-image-text-stacked`;

const CopyBlock = props => {
	const {
		modifier,
		image,
		hTag,
		heading,
		description,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
	});

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__container`}>
					{image && (
						<div className={`${ ns }__image-container`}>
							<Image {...image} />
						</div>
					)}
					<div className={`${ ns }__content`}>
						{heading && (
							<div className={`${ ns }__heading ${ hTag }`}>
								{heading}
							</div>
						)}
						{description && (
							<div className={`${ ns }__description`}>
								<RenderStaticHTML html={description} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

CopyBlock.defaultProps = {
	hTag: 'h2',
};

export default CopyBlock;
