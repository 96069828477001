import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-how-it-works`;

const ThreeTextUp = ({
	modifier,
	eyebrow,
	heading,
	subheading,
	blocks,
	hTag,
}) => {
	const HTag = hTag;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
	});

	const showTextContainer = eyebrow || heading || subheading;

	return (
		<div className={`${ rootClassnames } container-fluid`}>
			{showTextContainer && (
				<Reveal>
					<div className={`${ ns }__text-container`}>
						{eyebrow && (
							<p className={`${ ns }__eyebrow`}>
								{ eyebrow }
							</p>
						)}
						{heading && (
							<div className={`${ ns }__heading ${ HTag }`}>
								{ heading }
							</div>
						)}
						{subheading && (
							<p className={`${ ns }__subheading`}>
								{ subheading }
							</p>
						)}
					</div>
				</Reveal>
			)}
			{blocks && blocks.length && (
				<Reveal>
					<div className={`${ ns }__blocks`}>
						{blocks.map(block => {
							return (
								<div key={uniqueId('violux')} className={`${ ns }__block`}>
									{block.image && (
										<div className={`${ ns }__block-image`}>
											<img alt={'Step demonstration'} src={` ${ block.image } `} />
										</div>
									)}
									{block.heading && (
										<div className={`${ ns }__block-heading h5`}>{ block.heading }</div>
									)}
									{block.description && (
										<p className={`${ ns }__block-description`}>{ block.description }</p>
									)}
								</div>
							);
						})}
					</div>
				</Reveal>
			)}
		</div>
	);
};

ThreeTextUp.defaultProps = {
	hTag: 'h4',
};

export default ThreeTextUp;
